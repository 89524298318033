import Layout from '../components/layout';
import Contact from '../components/sections/services/contact';
import IntroSection from '../components/sections/services/intro';
import WhatWeDo from '../components/sections/services/whatWeDo';
import SEO from '../components/seo';
import { descriptions, titles } from '../consts/seo';

const Services = () => (
	<Layout>
		<SEO title={titles.services} description={descriptions.services} />
		<IntroSection />
		<WhatWeDo />
		<Contact />
	</Layout>
);

export default Services;
