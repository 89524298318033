import { Grid, makeStyles } from '@material-ui/core';
import { useRef, useEffect } from 'react';
import content from '../../../content/services/intro.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import colors from '../../../styles/colors';
import { defaultSectionWidth, largeText, smallSubtitle, smallText } from '../../../styles/dimensions';
import { StaticImage } from 'gatsby-plugin-image';
import { splitData } from '../../../helpers/splitData';
import AnimatedImage from '../../../elements/animatedImage';
// @ts-ignore
import serviceImage from '../../../images/services/photo_services.jpg';

const Intro = () => {
	const classes = useClasses();

	const headerRef = useRef<HTMLHeadingElement>(null);

	useEffect(() => {
		splitData(1.15, 0.025);

		setTimeout(() => {
			headerRef.current.style.opacity = '1';
		}, 1);
	}, []);

	return (
		<section>
			<div className={classes.header}>
				<Grid container className={classes.defaultWidth}>
					<Grid item md={1}></Grid>
					<Grid item xs={9} sm={6} md={4} className={classes.imageColumn} data-scroll data-scroll-speed="2">
						
						<AnimatedImage image={serviceImage} className={classes.animatedImage} />

						<StaticImage
							src="../../../images/services/photo_services.jpg"
							alt=""
							layout="constrained"
							loading="eager"
							placeholder="blurred"
							className={classes.image}
							aria-hidden="true"
						/>

						<StaticImage
							src="../../../images/services/circle.png"
							alt=""
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							className={classes.circle}
							aria-hidden="true"
						/>
					</Grid>
					<Grid item xs={3} sm={6} className={classes.titleColumn}>
						<h2 className={classes.title} data-splitting ref={headerRef}>{content.header.title}</h2>
					</Grid>
				</Grid>
			</div>

			<Grid container className={`${classes.defaultWidth} ${classes.project}`}>
				<Grid item md={1} lg={2}></Grid>
				<Grid item xs={12} sm={5} md={4} lg={3}>
					<h2 className={classes.projectTitle} data-scroll data-scroll-speed="2">
						{content.project.title}
					</h2>
				</Grid>
				<Grid item xs={12} sm={7} md={6} lg={5}>
					<p className={classes.content} data-scroll data-scroll-speed="2">
						{content.project.content}
					</p>
					<p className={classes.references} data-scroll data-scroll-speed="2">
						{content.project.references}
					</p>
				</Grid>
				<Grid item md={1} lg={2}></Grid>
			</Grid>
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	defaultWidth: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
	},
	project: {
		marginTop: '14.25em',
		marginBottom: '12em',
		'@media (max-width: 959px)': {
			marginTop: '7.5em',
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		'@media (max-width: 599px)': {
			marginTop: '3.5em',
			marginBottom: '7.5em',
		},
	},
	header: {
		backgroundColor: colors.backgroundBlack,
		paddingTop: 'calc(7.625em + 2px)',
		maxHeight: '472px',
		'@media (max-width: 599px)': {
			maxHeight: '350px',
		},
	},
	title: {
		color: colors.white,
		opacity: 0,
		'@media (min-width: 960px) and (max-width: 1199px)': {
			transform: 'translateY(-0.75em)',
		},
		'@media (max-width: 959px)': {
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
		},
		'@media (max-width: 599px)': {
			transform: 'translateY(-4em)',
		},
		'@media (min-width: 1200px)': {
			transform: 'translateY(-0.75em)',
		},
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	titleColumn: {
		marginTop: '12em',
		marginBottom: '9.5em',
		marginLeft: '-8.33333333%',
		position: 'relative',
		zIndex: 2,
		'@media (max-width: 599px)': {
			position: 'absolute',
			maxWidth: '80%',
			marginLeft: '15%',
		},
	},
	projectTitle: {
		margin: 0,
		'@media (max-width: 699px)': {
			fontSize: smallSubtitle.fontSize,
			lineheight: smallSubtitle.lineHeight,
			marginBottom: '0.625em',
		},
	},
	content: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textGrey,
		margin: 0,
		marginBottom: '0.75em',
	},
	references: {
		fontFamily: MetroSans.light,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textGrey,
		textTransform: 'uppercase',
		margin: 0,
		marginTop: '0.75em',
		letterSpacing: '1px',
	},
	imageColumn: {
		position: 'relative',
		top: '3.875em',
		'@media (max-width: 959px)': {
			maxHeight: '472px',
		},
		'@media (max-width: 599px)': {
			top: '-4.625em',
		},
	},
	image: {
		position: 'relative',
		zIndex: 1,
		minHeight: '400px',
		maxHeight: '100%',
		'@media (min-width: 1200px)': {
			display: 'none !important',
		},
		'@media (max-width: 449px)': {
			minHeight: '450px',
			height: 'auto',
		},
	},
	animatedImage: {
		position: 'relative',
		zIndex: 1,
		height: 'auto !important',
		minHeight: '400px',
		maxHeight: '100%',
		maxWidth: '100%',
		'@media (min-width: 770px)': {
			height: '500px',
		},
		'@media (max-width: 769px)': {
			minHeight: '380px',
		},
		'@media (max-width: 1199px)': {
			display: 'none !important',
		},
	},
	circle: {
		// @ts-ignore
		position: 'absolute !important',
		transform: 'translate(-57.5%, -5%)',
		zIndex: 0,
		maxWidth: 'unset',
		width: '443px',
		height: '443px',
		'@media (max-width: 549px)': {
			transform: 'translate(-64%, 0%)',
		},
		'@media (max-width: 449px)': {
			transform: 'translate(-70%, 0%)',
		},
	},
});
