import { CircularProgress, Grid, makeStyles, Snackbar, TextField } from '@material-ui/core';
import { useState, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import content from '../../../content/services/contact.json';
import Rigraf from '../../../fonts/rigraf/regular/rigraf';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import colors from '../../../styles/colors';
import {
	defaultSectionWidth,
	emphasizedSizeText,
	mobileTinyText,
	pageTitle,
	smallText,
	tinyText,
} from '../../../styles/dimensions';
import { Alert } from '@material-ui/lab';
import { Severity, SnackbarData } from '../careers/pages/contact';
import service from '../../../service/service';
import { ContactOther } from '../../../types/contactOther';
import MainButton from '../../../elements/mainButton';
import { StaticImage } from 'gatsby-plugin-image';
import ErrorStaticImage from '../../../elements/errorStaticImage';

interface ClassProps {
	loading: boolean;
}

const Contact = () => {
	const [loading, setLoading] = useState(false);
	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		open: false,
		severity: Severity.success,
		message: '',
	});

	const { handleSubmit, control, reset } = useForm();

	const classes = useClasses({ loading });

	const onSubmit = useCallback((values) => {
		setLoading(true);
		service.contactOther(ContactOther.fromForm(values)).then((response) => {
			if (response === true) {
				setSnackbarData({
					open: true,
					severity: Severity.success,
					message: content.alertMessages.success,
				});
				reset();
			} else {
				setSnackbarData({ open: true, severity: Severity.error, message: content.alertMessages.error });
			}
			setLoading(false);
		});
	}, []);

	const handleSnackbarClose = useCallback(() => {
		setSnackbarData({ open: false, severity: Severity.success, message: '' });
	}, []);

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item xs={12} sm={7} className={classes.talkColumn}>
					<StaticImage
						src="../../../images/services/contact_circle.png"
						alt=""
						className={classes.circle}
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						aria-hidden="true"
					/>

					<p className={classes.here}>{content.trust.here}</p>
					<p className={classes.talk}>{content.trust.talk}</p>
				</Grid>
				<Grid item sm={5}></Grid>

				<Grid item sm={7}></Grid>
				<Grid item xs={12} sm={5}>
					<h2 className={classes.header}>{content.contact.title}</h2>

					<form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									placeholder={content.contact.name.placeholder}
									type={content.contact.name.type}
									classes={{ root: classes.inputFormControl }}
									InputProps={{
										classes: { underline: classes.inputUnderline, input: classes.input },
									}}
									error={Boolean(error)}
									helperText={
										error && (
											<>
												<ErrorStaticImage />
												<span className={classes.fieldErrorSpan}>{error?.message}</span>
											</>
										)
									}
									FormHelperTextProps={{ classes: { error: classes.fieldErrorContainer } }}
								/>
							)}
							name={content.contact.name.name}
							control={control}
							defaultValue=""
							rules={{ required: content.contact.name.fieldError }}
						/>

						<Controller
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									placeholder={content.contact.email.placeholder}
									type={content.contact.email.type}
									classes={{ root: classes.inputFormControl }}
									InputProps={{
										classes: { underline: classes.inputUnderline, input: classes.input },
									}}
									error={Boolean(error)}
									helperText={
										error && (
											<>
												<ErrorStaticImage />
												<span className={classes.fieldErrorSpan}>{error?.message}</span>
											</>
										)
									}
									FormHelperTextProps={{ classes: { error: classes.fieldErrorContainer } }}
								/>
							)}
							name={content.contact.email.name}
							control={control}
							defaultValue=""
							rules={{ required: content.contact.email.fieldError }}
						/>

						<Controller
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									placeholder={content.contact.question.placeholder}
									type={content.contact.question.type}
									classes={{ root: classes.inputFormControl }}
									InputProps={{
										classes: { underline: classes.inputUnderline, input: classes.input },
									}}
									error={Boolean(error)}
									helperText={
										error && (
											<>
												<ErrorStaticImage />
												<span className={classes.fieldErrorSpan}>{error?.message}</span>
											</>
										)
									}
									FormHelperTextProps={{ classes: { error: classes.fieldErrorContainer } }}
								/>
							)}
							name={content.contact.question.name}
							control={control}
							defaultValue=""
							rules={{ required: content.contact.question.fieldError }}
						/>

						<MainButton type="submit" disabled={loading} text={content.contact.submit} />

						<CircularProgress color="inherit" aria-busy="true" classes={{ root: classes.spinnerRoot }} />
					</form>
				</Grid>
			</Grid>
			<Snackbar open={snackbarData.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarData.severity}
					variant="filled"
					classes={{ message: classes.snackbarAlert }}>
					{snackbarData.message}
				</Alert>
			</Snackbar>
		</section>
	);
};

export default Contact;

const useClasses = makeStyles({
	header: {
		marginTop: '0',
		marginBottom: '0.95em',
		'@media (max-width: 599px)': {
			marginBottom: 0,
		},
	},
	section: {
		maxWidth: defaultSectionWidth,
		margin: '6.375em auto',
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 1349px)': {
			marginTop: '10.375em',
		},
		'@media (max-width: 599px)': {
			marginTop: '8.375em',
		},
	},
	button: {
		fontFamily: Rigraf.semiBold,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		width: '9.0625em',
		height: '2.75em',
		boxSizing: 'border-box',
		padding: '0.625em 2.0625em',
		background: 'linear-gradient(137.83deg, #1F272E 0%, #29343D 46.95%, #AE1E36 100%)',
		color: `${colors.white} !important`,
		marginTop: '3.125em',
		position: 'relative',
		right: '-4px',
		top: '4px',
		transition: 'top 0.2s linear, right 0.2s linear',
		border: 0,
		outline: 0,
		cursor: 'pointer',
		borderBottom: '3px solid transparent',
		'& span': {
			fontFamily: Rigraf.semiBold,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
			position: 'relative',
			right: '4px',
			top: '-4px',
			transition: 'top 0.2s linear, right 0.2s linear',
		},
		'&::after': {
			content: '""',
			position: 'absolute',
			width: 'calc(9.0625em - 4px)',
			height: 'calc(2.75em - 4px)',
			border: `2px solid ${colors.buttonOutlineBlack}`,
			right: '4px',
			top: '-4px',
			transition: 'top 0.2s linear, right 0.2s linear',
		},
		'&:hover': {
			right: '0px',
			top: '0px',
			'& span': {
				right: '0px',
				top: '0px',
			},
		},
		'&:hover:after': {
			right: '0px',
			top: '0px',
		},
	},
	here: {
		fontFamily: MetroSans.book,
		fontSize: tinyText.fontSize,
		lineHeight: tinyText.lineHeight,
		color: colors.textGrey,
		'@media (max-width: 960px)': {
			paddingTop: '2.5em',
		},
	},
	talk: {
		fontFamily: RigrafExpanded.bold,
		fontSize: emphasizedSizeText.fontSize,
		lineHeight: emphasizedSizeText.lineHeight,
		margin: '0.027777777777777776em 0',
		'@media (max-width: 699px)': {
			fontSize: pageTitle.fontSize,
			lineHeight: pageTitle.lineHeight,
		},
	},
	input: {
		fontFamily: Rigraf.regular,
		fontSize: smallText.fontSize + ' !important',
		lineHeight: smallText.lineHeight + ' !important',
		color: colors.textBlack,
		'&::placeholder': {
			fontFamily: Rigraf.regular,
		},
	},
	inputUnderline: {
		borderBottomColor: colors.textBlack,
		'&::after': {
			borderBottomColor: colors.textBlack,
		},
		'&.MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottomColor: colors.textBlack,
		},
	},
	inputFormControl: {
		margin: '1em 0',
	},
	circle: {
		// @ts-ignore
		position: 'absolute !important',
		transform: 'translate(-110%, -25%)',
		zIndex: -1,
		borderRadius: '50%',
		'@media (max-width: 1349px)': {
			transform: 'translate(-50%, calc(-11em - 50%))',
			left: '50%',
		},
		'@media (max-width: 599px)': {
			transform: 'translate(-50%, calc(-10em - 50%))',
			left: '50%',
		},
	},
	talkColumn: {
		'@media (max-width: 599px)': {
			marginBottom: '1.75em',
		},
	},
	spinnerRoot: (props: ClassProps) => ({
		display: props.loading ? 'inline-block' : 'none',
		position: 'relative',
		top: '18px',
		left: '30px',
		color: colors.textBlack,
	}),
	snackbarAlert: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
	},
	fieldErrorContainer: {
		position: 'absolute',
		bottom: 0,
		transform: 'translateY(120%)',
		whiteSpace: 'nowrap',
	},
	fieldErrorSpan: {
		color: colors.errorRed,
		fontFamily: MetroSans.regular,
		fontSize: mobileTinyText.fontSize,
		lineHeight: mobileTinyText.lineHeight,
		marginLeft: '0.6666666666666666em',
	},
});
