import { Grid, makeStyles } from '@material-ui/core';
import { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import content from '../../../content/services/whatWeDo.json';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import Rigraf from '../../../fonts/rigraf/regular/rigraf';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import colors from '../../../styles/colors';
import {
	defaultSectionWidth,
	largeText,
	pageSubtitle,
	pageTitle,
	regularText,
	smallSubtitle,
	smallText,
} from '../../../styles/dimensions';
import Brain from '../../animations/brain';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { ScrollEvent, useScrollContext } from '../../../hooks/useScrollContext';
import { StaticImage } from 'gatsby-plugin-image';
import Blockchain from '../../../images/services/blockchain';

interface ClassProps {
	brainVisible: boolean;
}

const WhatWeDo = () => {
	const [brainVisible, setBrainVisible] = useState(false);
	const [isLarge, setIsLarge] = useState(false);

	const scroll = useScrollContext();

	const classes = useClasses({ brainVisible });

	useEffect(() => {
		if (typeof window !== 'undefined' && scroll?.scroll?.windowWidth >= 600) {
			const brainAnimation = document.getElementById('brain-animation-container');

			scroll.on('scroll', (event: ScrollEvent) => {
				brainAnimation.style.transform = `translateY(${event.scroll.y}px)`;
			});

			if (scroll.scroll.windowHeight >= 1000) {
				setIsLarge(true);
			}

			const handleResize = () => {
				if (scroll.scroll.windowHeight >= 1000 && !isLarge) {
					setIsLarge(true);
				} else if (scroll.scroll.windowHeight < 1000 && isLarge) {
					setIsLarge(false);
				}
			};

			window.addEventListener('resize', handleResize, false);

			return () => {
				window.removeEventListener('resize', handleResize, false);
			};
		}
	}, [scroll]);

	const repeatingContent = useMemo(() => {
		const elements: JSX.Element[] = [];
		for (let i = 0; i < 100; i++) {
			if (i === 0) {
				elements.push(
					<h1 key={i} className={classes.whatWeDoText}>
						{content.title}
					</h1>
				);
			} else {
				elements.push(
					<span key={i} className={classes.whatWeDoText} aria-hidden="true">
						{content.title}
					</span>
				);
			}
		}
		return elements;
	}, []);

	const handleVisibilityChange = useCallback((isVisible: boolean) => {
		setBrainVisible(isVisible);
	}, []);

	return (
		<section>
			<div aria-label={content.title} data-scroll data-scroll-speed="2">
				<div className={classes.header}>{repeatingContent}</div>
			</div>

			<div className={classes.brainContainer} id="brain-animation-container">
				<StaticImage
					src="../../../images/services/mesh.svg"
					alt=""
					className={classes.mesh}
					layout="constrained"
					loading="lazy"
					placeholder="blurred"
					aria-hidden="true"
				/>
				<Brain />
			</div>

			<ReactVisibilitySensor
				onChange={handleVisibilityChange}
				partialVisibility
				minTopValue={isLarge ? 700 : 500}
				offset={{ top: isLarge ? 900 : 700 }}>
				<Grid container className={classes.grid}>
					{content.content.map((item, key) => (
						<Fragment key={key}>
							<Grid className={classes.intro} item xs={12} sm={5} data-scroll data-scroll-speed="2">
								<span className={classes.numbers}>{`0${key + 1}`}</span>
								<h3 className={classes.sectionHeader}>{item.left.title}</h3>
								<p className={classes.sectionDescription}>{item.left.description}</p>
							</Grid>

							<Grid item sm={1} className={classes.divider}></Grid>
							<Grid item sm={1}></Grid>

							<Grid item xs={12} sm={5} className={classes.lastColumn} data-scroll data-scroll-speed="2">
								{item.right.map((segment, key) => (
									<div key={key}>
										<h5 className={classes.subsectionHeader}>{segment.title}</h5>
										<p className={classes.subsectionContent}>{segment.description}</p>
									</div>
								))}
								{key === 2 && (
									<div className={classes.techStackContainer}>
										<span className={classes.techStackDescription}>
											{content.techStack.description}
										</span>
										<StaticImage
											src="../../../images/services/bubble_tech_stack_illustration.png"
											alt="Tech Stack"
											className={classes.techStackImage}
											layout="constrained"
											loading="lazy"
											placeholder="blurred"
										/>
									</div>
								)}
								{key === 3 && (
									<div className={classes.blockChainContainer}>
										<Blockchain />
									</div>
								)}
							</Grid>
						</Fragment>
					))}
				</Grid>
			</ReactVisibilitySensor>

			<div className={classes.last}>
				<p data-scroll data-scroll-speed="2">
					{content.last.part1}
				</p>
				<p data-scroll data-scroll-speed="2">
					{content.last.part2}
				</p>
			</div>
		</section>
	);
};

export default WhatWeDo;

const useClasses = makeStyles({
	intro: {
		'@media (max-width: 599px)': {
			paddingTop: '2em',
		},
	},
	header: {
		overflow: 'visible',
		whiteSpace: 'nowrap',
		marginTop: '9em',
		marginBottom: '6.75em',
		width: '819.3928571428572em',
		animation: '$loop 90s linear infinite',
		'@media (max-width: 599px)': {
			marginTop: '7.5em',
			marginBottom: '15em',
		},
	},
	'@keyframes loop': {
		from: {
			transform: 'translateX(-50%)',
		},
		to: {
			transform: 'translateX(0%)',
		},
	},
	whatWeDoText: {
		display: 'inline',
		margin: 0,
		fontFamily: RigrafExpanded.bold,
		fontWeight: 'normal',
		fontSize: pageTitle.fontSize,
		lineHeight: pageTitle.lineHeight,
		padding: '0px 0.5714285714285714em',
		color: colors.textBlack,
		userSelect: 'none',
		'@media (max-width: 599px)': {
			fontSize: smallSubtitle.fontSize,
			lineHeight: smallSubtitle.lineHeight,
			padding: '0px 0.625em',
		},
	},
	last: {
		backgroundColor: colors.backgroundBlack,
		color: colors.white,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		padding: '16.5625rem 0',
		marginTop: '-11.75em',
		'& p': {
			fontFamily: RigrafExpanded.semiBold,
			fontSize: pageSubtitle.fontSize,
			lineHeight: pageSubtitle.lineHeight,
			margin: '0.375em 0',
			paddingLeft: '1rem',
			paddingRight: '1rem',
			'@media (max-width: 599px)': {
				fontSize: regularText.fontSize,
				lineHeight: regularText.lineHeight,
			},
		},
		'& p:last-child': {
			backgroundColor: colors.white,
			color: colors.textBlack,
			padding: '0 0.45em',
			'@media (max-width: 599px)': {
				padding: 0,
				width: 'calc(100% - 2rem)',
			},
		},
		'@media (max-width: 599px)': {
			padding: '7.125em 0',
			marginTop: 0,
		},
	},
	grid: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		position: 'relative',
		zIndex: 1,
		'& :last-of-type(div)': {
			marginBottom: '-11.75em',
		},
		'@media (max-width: 1199px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		'& $lastColumn:last-of-type': {
			position: 'relative',
			top: '5em',
			marginBottom: 'calc(17.125em + 11.75em)',
			'@media (max-width: 599px)': {
				marginBottom: '8em',
			},
		},
	},
	numbers: {
		fontFamily: RigrafExpanded.semiBold,
		fontSize: largeText.fontSize,
		lineHeight: largeText.lineHeight,
		background:
			'linear-gradient(135.14deg, #19D184 0%, #19B0D1 14.44%, #5359EB 29.4%, #5B00CE 45.39%, #8D05A3 58.94%, #C40248 75.59%, #FFCD1E 91.72%)',
		'-webkit-background-clip': 'text',
		'-webkit-text-fill-color': 'transparent',
		'@media (max-width: 599px)': {
			fontFamily: Rigraf.semiBold,
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
		},
	},
	sectionHeader: {
		marginTop: '0.125em',
		marginBottom: '0.375em',
	},
	sectionDescription: {
		margin: '0.75em 0',
		color: colors.textGrey,
	},
	divider: {
		marginTop: '-1.25em',
		borderRight: `1px solid ${colors.dividerGray}`,
		position: 'relative',
		zIndex: 2,
		pointerEvents: 'none',
		'@media (max-width: 599px)': {
			borderRight: 0,
		},
	},
	lastColumn: {
		position: 'relative',
		top: '4.1em',
		marginBottom: '17.125em',
		'@media (max-width: 599px)': {
			marginBottom: '4.75em',
			marginTop: '-2em',
		},
	},
	subsectionHeader: {
		marginTop: 0,
		marginBottom: '0.4em',
	},
	subsectionContent: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		marginTop: '0.5em',
		marginBottom: '1.5em',
		color: colors.textGrey,
	},
	techStackContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: '3.75em',
		'@media (max-width: 959px)': {
			flexDirection: 'column-reverse',
		},
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	techStackDescription: {
		fontFamily: RigrafExpanded.semiBold,
		color: colors.textBlack,
		'@media (max-width: 959px)': {
			marginTop: '1em',
		},
	},
	techStackImage: {
		maxWidth: '250px',
	},
	blockChainContainer: {
		marginTop: '3.75em',
		'& > svg': {
			maxWidth: '100%',
		},
	},
	brainContainer: {
		pointerEvents: 'none',
		'@media (min-width: 600px)': {
			position: 'fixed',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			height: '100vh',
			width: '100vw',
			opacity: (props: ClassProps) => (props.brainVisible ? 1 : 0),
			transition: 'opacity 0.2s linear',
		},
		'@media (max-width: 599px)': {
			position: 'absolute',
		},
	},
	mesh: {
		position: 'absolute',
		left: '50%',
		transform: 'translate(-50%, -16em)',
		pointerEvents: 'none',
		'@media (min-width: 600px)': {
			top: '50%',
			transform: 'translate(-50%, -50%)',
		},
	},
});
