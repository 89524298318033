export class ContactOther {
	public name: string;
	public email: string;
	public question: string;

	constructor(name: string, email: string, question: string) {
		this.name = name;
		this.email = email;
		this.question = question;
	}

	public toJSON() {
		return {
			name: this.name,
			email: this.email,
			question: this.question,
		};
	}

	public static fromForm(maybe: any): ContactOther {
		return new ContactOther(maybe.name, maybe.email, maybe.question);
	}
}
