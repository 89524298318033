import { useEffect } from 'react';
import {
	Engine,
	SceneLoader,
	Color4,
	ArcRotateCamera,
	Axis,
	Space,
	DirectionalLight,
	Vector3,
	Color3,
} from '@babylonjs/core/Legacy/legacy';
import { makeStyles } from '@material-ui/core';
import { ScrollEvent, useScrollContext } from '../../hooks/useScrollContext';

const Brain = () => {
	const scroll = useScrollContext();

	const classes = useClasses();

	useEffect(() => {
		if (typeof window !== 'undefined' && Engine.isSupported() && scroll) {
			const canvas = document.getElementById('renderCanvas');
			// @ts-ignore
			const engine = new Engine(canvas, true, { xrCompatible: false });
			engine.disableUniformBuffers = true;
			SceneLoader.Load('/', 'brain-simple-mesh.babylon', engine, (scene) => {
				scene.executeWhenReady(() => {
					scene.clearColor = new Color4(0, 0, 0, 0);

					scene.stopAllAnimations();

					const camera = new ArcRotateCamera(
						'camera',
						-Math.PI / 2,
						Math.PI / 2,
						10,
						new Vector3(0, 1, 0),
						scene
					);

					if (window.innerWidth < 600) {
						camera.useAutoRotationBehavior = true;
						camera.autoRotationBehavior.idleRotationSpeed = 0.2;
					} else {
						scroll.on('scroll', (event: ScrollEvent) => {
							camera.alpha = event.scroll.y * 0.0012;
						});
					}

					scene.meshes[0].rotate(Axis.X, -0.64577182324, Space.LOCAL);

					scene.setActiveCameraByName('camera');
					scene.activeCamera.attachControl(canvas);
					scene.lights = scene.lights.filter((x) => x === null);

					const lightFront = new DirectionalLight('lightFront', new Vector3(0, 0, -2), scene);
					lightFront.diffuse = new Color3(0.9, 0.9, 0.9);
					lightFront.specular = new Color3(0.6, 0.6, 0.6);

					const lightBack = new DirectionalLight('lightBack', new Vector3(0, 0, 2), scene);
					lightBack.diffuse = new Color3(0.9, 0.9, 0.9);
					lightBack.specular = new Color3(0.6, 0.6, 0.6);

					const lightLeft = new DirectionalLight('lightLeft', new Vector3(2, 0, 0), scene);
					lightLeft.diffuse = new Color3(0.9, 0.9, 0.9);
					lightLeft.specular = new Color3(0.6, 0.6, 0.6);

					const lightRight = new DirectionalLight('lightRight', new Vector3(-2, 0, 0), scene);
					lightRight.diffuse = new Color3(0.9, 0.9, 0.9);
					lightRight.specular = new Color3(0.6, 0.6, 0.6);

					scroll.update();

					engine.runRenderLoop(() => {
						scene.render();
					});
				});
			});
		}
	}, [scroll]);

	return <canvas id="renderCanvas" className={classes.canvas}></canvas>;
};

export default Brain;

const useClasses = makeStyles({
	canvas: {
		position: 'absolute',
		height: '300px',
		width: '300px',
		left: '50%',
		outline: 0,
		opacity: 0.9,
		pointerEvents: 'none',
		transform: 'translate(-50%, -16em) scale(0.5)',
		'@media (min-width: 600px)': {
			top: '50%',
			transform: 'translate(-50%, -50%)',
		},
	},
});
